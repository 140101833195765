@use "@angular/material" as mat;
// @import "@angular/material/theming";
@import "src/variables.scss";

@import "./app/auxiliares/mat-table-responsive/mat-table-responsive.scss";
@import "assets/styles/ng-select.scss";

@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-typographies($typography);
@include mat.all-component-themes($theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background-color: $background;
}

.no-info {
  color: $gris;
  font-style: italic;
}

.label-green {
  background-color: green;
  padding: 0.25em 0.5em;
  margin: 2px 0 !important;
  border-radius: 20px;
  color: white;
}

.label-red {
  background-color: red;
  padding: 0.25em 0.5em;
  margin: 2px 0 !important;
  border-radius: 20px;
  color: white;
}

// Graficos
.highcharts-credits {
  display: none;
}

:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.v-align {
  display: flex;
  align-items: center;
}

.font08 {
  font-size: 0.8em;
}

.nowrap {
  white-space: nowrap;
}

mat-form-field {
  width: 100%;
}

mat-progress-bar {
  position: absolute !important;
  top: 0px;
}

ngx-mat-color-toggle mat-icon {
  transform: scale(2);
}

.no-shadow {
  box-shadow: none !important;
}

.input-hide {
  position: fixed;
  top: -2000px;
}

.menuLateral {
  width: 90%;
}

// Para evitar el fondo de autocompletado
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #e8f0fe !important;
}

mat-form-field.no-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.modal-header {
  background: $primary;
  color: $primaryContrast;
  margin-top: -24px !important;
  margin-left: -24px !important;
  margin-right: -24px !important;
  padding: 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.loadSpinnerButton {
  justify-content: center;
  height: 36px !important;
  align-items: center;
}

.botonSpinner {
  height: 36px !important;
  width: 100px !important;
  // margin-bottom: 1em !important;
}

.link-silo {
  cursor: pointer;
  color: $link;
  font-weight: 700;
}

.link {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  cursor: pointer;
}

.sin-asignar {
  color: $gris;
  font-style: italic;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}

.ngx-mat-timepicker table {
  margin: 1em;
  padding: 10px;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
  border-top: 3px solid $primary;
}

.icon-text {
  display: flex;
  align-items: center;
}

.subgrupo {
  padding: 1em;
  background-color: #f5f5f5;
  border: 1px solid $primary;
  border-radius: 5px;
  margin-bottom: 1em;
}

// Alertas

* {
  .alerta1 {
    background-color: $alerta1;
    color: black;
    height: 35px;
    width: 80px;
    border-radius: 3px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  .alerta2 {
    background-color: $alerta2;
    color: white;
    height: 35px;
    width: 80px;
    border-radius: 3px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  .alerta3 {
    background-color: $alerta3;
    color: white;
    height: 35px;
    width: 80px;
    border-radius: 3px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
}

* {
  .alertaCirculo0 {
    background-color: $alerta0;
    color: white;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alertaCirculo1 {
    background-color: $alerta1;
    color: black;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alertaCirculo2 {
    background-color: $alerta2;
    color: white;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alertaCirculo3 {
    background-color: $alerta3;
    color: white;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

* {
  .alertaCirculoSm0 {
    background-color: $alerta0;
    color: white;
    height: 17px;
    width: 17px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    padding: 3px;
    align-items: center;
  }

  .alertaCirculoSm1 {
    background-color: $alerta1;
    color: black;
    height: 17px;
    width: 17px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    padding: 3px;
    align-items: center;
  }

  .alertaCirculoSm2 {
    background-color: $alerta2;
    color: white;
    height: 17px;
    width: 17px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    padding: 3px;
    align-items: center;
  }

  .alertaCirculoSm3 {
    background-color: $alerta3;
    color: white;
    height: 17px;
    width: 17px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    padding: 3px;
    align-items: center;
  }
}

// Margenes

* {
  .m-auto {
    margin: auto !important;
  }
  .m-0 {
    margin: 0 !important;
  }

  .m-1 {
    margin: 0.25em !important;
  }

  .m-2 {
    margin: 0.5em !important;
  }

  .m-3 {
    margin: 1em !important;
  }

  .m-4 {
    margin: 1.5em !important;
  }

  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-1 {
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }

  .mx-2 {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  .mx-3 {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .mx-4 {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .my-1 {
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
  }

  .my-2 {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .my-3 {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .my-4 {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: 0.25em !important;
  }

  .mt-2 {
    margin-top: 0.5em !important;
  }

  .mt-3 {
    margin-top: 1em !important;
  }

  .mt-4 {
    margin-top: 2em !important;
  }

  .mb-1 {
    margin-bottom: 0.25em !important;
  }

  .mb-2 {
    margin-bottom: 0.5em !important;
  }

  .mb-3 {
    margin-bottom: 1em !important;
  }

  .mb-4 {
    margin-bottom: 2em !important;
  }

  .mb-5 {
    margin-bottom: 3em !important;
  }

  .mb-6 {
    margin-bottom: 4em !important;
  }

  .mb-7 {
    margin-bottom: 6em !important;
  }

  .ml-1 {
    margin-left: 0.25em !important;
  }

  .ml-2 {
    margin-left: 0.5em !important;
  }

  .ml-3 {
    margin-left: 1em !important;
  }

  .mr-1 {
    margin-right: 0.25em !important;
  }

  .mr-2 {
    margin-right: 0.5em !important;
  }

  .mr-3 {
    margin-right: 1em !important;
  }
}

// Padding

* {
  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: 0.25em !important;
  }

  .p-2 {
    padding: 0.5em !important;
  }

  .p-3 {
    padding: 1em !important;
  }

  .p-4 {
    padding: 1.5em !important;
  }

  .px-1 {
    padding-left: 0.25em !important;
    padding-right: 0.25em !important;
  }
  .px-2 {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
  .px-3 {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .pl-1 {
    padding-left: 0.25em !important;
  }

  .pl-2 {
    padding-left: 0.5em !important;
  }

  .py-1 {
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
  }
  .py-2 {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
  }
  .py-3 {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }
}

// Tablas

* {
  .titulo {
    margin: 15px !important;
    border-left: $primary 15px solid;
    padding: 0.75em;
  }

  .table-container {
    margin: 15px;
    width: calc(100% - 2em);
    background: white;

    .btn-container {
      width: calc(100% - 23px);
      height: 1px;
      text-align: end;
      z-index: 100;
    }
    .btn-container * {
      top: -23px;
      margin-left: 0.25em;
      margin-right: 0.25em;
      z-index: 100;
    }
    .filtro {
      margin-top: 1em;
      margin-left: 1em;
      width: calc(100% - 2em);
    }
    .action-bnt-cnt button {
      margin: 0.25em;
      box-shadow: none;
    }
    .mobile-label {
      display: none;
    }
    .mat-table {
      width: 100%;
    }
    mat-cell {
      padding-left: 0.5em;
    }
    @media (max-width: 599px) {
      .mobile-label {
        min-width: 100px;
        width: 100px;
        display: inline-block;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mat-header-row {
        display: none !important;
      }

      .mat-row {
        flex-direction: column;
        align-items: start;
        padding: 8px 24px;
        min-height: unset;
      }

      .mat-cell {
        min-height: unset;
        margin: 2px;
        margin-bottom: 10px;
      }

      mat-cell {
        padding-left: 0 !important;
      }
    }
  }
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-flex-start {
  display: flex;
  justify-content: flex-start;
}

.row-space-between {
  display: flex;
  justify-content: space-between;
}

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

///////////////////////////////
// SCROLL BAR
///////////////////////////////

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // border-radius: 10px;
  background-color: $background;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: $background;
}

::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary;
}

mat-card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 0 1.5em 1.5em 1.5em;
}

// SnackBar
* {
  .red-snackbar > div {
    background-color: #f44336 !important;
    .mat-mdc-snack-bar-label {
      color: white !important;
    }
  }
  .green-snackbar > div {
    background-color: #4caf50 !important;
    .mat-mdc-snack-bar-label {
      color: white !important;
    }
  }
  .yellow-snackbar > div {
    background-color: #ffeb3b !important;
    .mat-mdc-snack-bar-label {
      color: black !important;
    }
  }
}
// SnackBar
