@use "@angular/material" as mat;

//
// Paletas de colores
//

$accent-palette-colors: (
  50: #f1f8e9,
  100: #dcedc9,
  200: #c5e1a5,
  300: #aed580,
  400: #9ccc65,
  500: #8bc34a,
  600: #83bd43,
  700: #78b53a,
  800: #6eae32,
  900: #5ba122,
  A100: #ebffdd,
  A200: #ceffaa,
  A400: #b0ff77,
  A700: #a2ff5d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary-palette-colors: (
  50: #e2e9ed,
  100: #b6c8d1,
  200: #86a3b3,
  300: #557e95,
  400: #30637e,
  500: #0c4767,
  600: #0a405f,
  700: #083754,
  800: #062f4a,
  900: #032039,
  A100: #6fb2ff,
  A200: #3c97ff,
  A400: #097bff,
  A700: #006fef,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$warn-palette-colors: (
  50: #f4e8e8,
  100: #e4c5c6,
  200: #d39ea0,
  300: #c17779,
  400: #b35a5d,
  500: #a63d40,
  600: #9e373a,
  700: #952f32,
  800: #8b272a,
  900: #7b1a1c,
  A100: #ffb4b6,
  A200: #ff8184,
  A400: #ff4e52,
  A700: #ff3539,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

//
// Typography
//

$typography: mat.m2-define-typography-config(
  $font-family: "Raleway",
);

$primary-palette: mat.m2-define-palette($primary-palette-colors);
$accent-palette: mat.m2-define-palette($accent-palette-colors);
$warn-palette: mat.m2-define-palette($warn-palette-colors);
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $typography,
  )
);

// Colores

$background: #fdfffc;

$primary200: mat.m2-get-color-from-palette($primary-palette, "200");
$primary300: mat.m2-get-color-from-palette($primary-palette, "300");
$primary: mat.m2-get-color-from-palette($primary-palette, "default");
$primaryContrast: mat.m2-get-color-from-palette(
  $primary-palette,
  "default-contrast"
);
$primary900: mat.m2-get-color-from-palette($primary-palette, "darker");

$accent: mat.m2-get-color-from-palette($accent-palette, "default");
$accentContrast: mat.m2-get-color-from-palette(
  $accent-palette,
  "default-contrast"
);

$warn: mat.m2-get-color-from-palette($warn-palette, "default");

$alerta0: #026937;
$alerta1: #f1d302;
$alerta2: #ff9100;
$alerta3: #e4032c;
$link: #5867dd;
$gris: #bdbdbd;

:root {
  --primary-color: #{$primary};
  --primary-color-990: #{$primary900};
  --primary-color-contrast: #{$primaryContrast};

  --accent-color: #{$accent};
  --accent-color-contrast: #{$accentContrast};
  // --warn-color: #{$light-warn-color};
  // --warn-color-contrast: #{$light-warn-color-contrast};
  // --bg-color-solid: #{$light-bg-color-solid};
  // --bg-color: #{$light-bg-color};
  // --bg-color-85: #{$light-bg-color-85};
  // --fg-color: #{$light-fg-color};
  // --negro-transparente: #{$negro-transparente};
  // --blanco-transparente: #{$blanco-transparente};
  // --input-color: #{$light-input-color};
  // Colores
  // --azul: #{$azul};
  // --celeste: #{$celeste};
  // --rojo: #{$rojo};
  // --verde: #{$verde};
  // --amarillo: #{$amarillo};
  // --gris: #{$gris};
  // --naranja: #{$naranja};
  // --rosa: #{$rosa};
}
