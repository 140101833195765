.ng-select {
  background: var(--mdc-filled-text-field-container-color);
  padding: 0;
  margin-bottom: 22px;
  height: 56px;
  border-radius: 4px 4px 0 0;

  .ng-select-container {
    height: 100%;
    padding: 0 1em;
    color: var(--mat-sidenav-content-text-color);
  }

  .ng-placeholder,
  .ng-arrow,
  .ng-clear {
    color: var(--mat-sidenav-content-text-color) !important;
  }

  .ng-select-container:after {
    border-bottom: 1px solid var(--mat-sidenav-content-text-color);
  }

  input {
    color: var(--mdc-filled-text-field-input-text-color);
    font-size: 1em;
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    // background-color: var(--mat-option-selected-state-label-text-color);
    background-color: var(--mat-sidenav-content-text-color);
  }
}

.ng-dropdown-panel {
  background: var(--mat-select-panel-background-color);
  padding: 0.5em;

  .ng-option {
    padding: 0.5em 0;
  }

  .ng-star-inserted {
    color: var(--mdc-filled-text-field-input-text-color);
  }
}
